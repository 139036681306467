<template>
   <CContainer>
        <CRow>
        <CCol col="12" xl="12">
          <CCard>
            <CCardHeader>
              <h3>Sub Cuentas</h3>
            </CCardHeader>
            <template>
                  <CRow>
                    <CCol col="3" class="ml-3 mt-1">
                        <CButton color="primary" @click="modal_crear_nueva_sub_cuenta = true" v-if="$can('subcuenta.create')">Crear Usuario Sub Cuenta</CButton>
                    </CCol>
                  </CRow>   
            </template>
            <CCardBody>
              <CDataTable
                hover
                striped
                :items="items"
                :fields="fields"
                :items-per-page="10"
                :pagination="{ doubleArrows: false, align: 'center'}"
                column-filter
                @page-change="pageChange"
                
              >
                <!--<template #status="data">
                  <td>
                    <CBadge :color="getBadge(data.item.status)">
                      {{data.item.status}}
                    </CBadge>
                  </td>
                </template>-->
                <template #show_details="{item, index}">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="toggleDetails(item, index)"
                      >
                        {{Boolean(item._toggled) ? 'Ocultar' : 'Ver'}}
                      </CButton>
                    </td>
                  </template>
                  <template #saldo_actual="{item, index}">
                      <td>
                        {{creditosConversorMoneda(item.saldo_actual)}}
                      </td>
                  </template>
                  <template #details="{item}">
                    <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                      <CCardBody>
                        <!--<CMedia :aside-image-props="{ height: 102 }">-->
                          <h4>
                            {{item.name}}
                          </h4>
                          <p class="text-muted">Registrado Desdes: {{item.created_at.substr(0,10)}}</p>
                          <p class="text-muted">Estado Usuario: 
                            <CBadge :color="getBadge(item.status)">
                                {{item.status == 1 ? 'Activo' : 'Inactivo'}}
                            </CBadge>
                            </p>
                          <router-link :to="'/subcuentas/usuarios/recargas/'+item.iduser" v-if="$can('subcuenta.recargas')">
                              <CButton size="sm" color="info" >
                                Recargas
                              </CButton>
                          </router-link>
                          <router-link :to="'/usuarios/impuestos/'+item.iduser" v-if="$can('subcuenta.mesajes')">
                              <CButton size="sm" color="info">
                                Ultimos Mensajes
                              </CButton>
                          </router-link>
                          <CButton size="sm" color="info" @click="abrirModalCambiarPassword(item.iduser,item.name)" v-if="$can('subcuenta.cambiarpassword')">
                                Cambiar Password
                          </CButton>
                          <CButton size="sm" :color="item.marca_blanca == 1 ? 'warning' : 'success'" @click="abrirModalRevendedor(item.iduser,item.name,item.marca_blanca)" v-if="validaMarcaBlanca == 1">
                                {{item.marca_blanca == 1 ? 'Desactivar Revendedor' : 'Activar Revendedor'}}
                          </CButton>
                          <CButton size="sm" :color="item.status == 1 ? 'danger' : 'warning'" @click="cambiarStatusSubcuenta(item.iduser,item.name,item.status);" v-if="$can('subcuenta.status')">
                                {{item.status == 1 ? 'Desactivar' : 'Activar'}}
                          </CButton>
                        <!--</CMedia>-->
                      </CCardBody>
                    </CCollapse>
                  </template>
              </CDataTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <!-- Notificaciones-->
         <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
        </div>
        <!-- Modals-->
        <div>
          <CModal
            :show.sync="modal_crear_nueva_sub_cuenta"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="lg"
            color="success"
          >
          <template #header>
              <h6 class="modal-title">Crear una Sub Cuenta</h6>
              <CButtonClose @click="modal_crear_nueva_sub_cuenta = false" class="text-white"/>
            </template>
            <CRow>
              <CCol col="12">
                Por favor complete el formulario a continuacion le enviaremos un email de bienvenida con su nombre de usuario y contrasena inmediatamente.
              </CCol>
              <CCol col="12">
                 <CInput
                    placeholder="Nombre"
                    autocomplete="off"
                    minlength="3"
                    required
                    was-validated
                    v-model="form.name"
                    >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                    </CInput>
              </CCol>
              <CCol col="12">
                 <CInput
                    placeholder="Empresa"
                    autocomplete="off"
                    minlength="3"
                    required
                    was-validated
                    v-model="form.empresa"
                    >
                    <template #prepend-content><CIcon name="cil-building"/></template>
                    </CInput>
              </CCol>
              <CCol col="12">
                  <CInput
                    placeholder="Email"
                    autocomplete="off"
                    type="email"
                    prepend="@"
                    required
                    was-validated
                    v-model="form.email"
                    />
              </CCol>
            </CRow>
            <CRow class="justify-content-center">
                <CCol class="col-1">
                  <CSwitch
                    class="mr-1"
                    color="dark"
                    shape="pill"
                    variant="opposite"
                    :checked.sync="form.condiciones"
                  />
                </CCol>
                <CCol class="col-11">
                    <p> He leído los <a target="_blank" href="http://sistemasmasivos.com/envio/admin/alta/acuerdomailing.html">Términos de Servicio y políticas de confidencialidad</a> de Sistema Envio Masivo y estoy de acuerdo</p>
                </CCol>
              </CRow>  
            <template #footer>
              <CButton @click="modal_crear_nueva_sub_cuenta = false" color="danger">Cancelar</CButton>
              <CButton v-on:click.stop="crearSubCuenta($event)" color="success" v-if="$can('subcuenta.create')">Aceptar</CButton>
            </template>
          </CModal>
          <CModal
            :show.sync="modal_cambiar_password"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="lg"
            color="success"
          >
            <template #header>
              <h6 class="modal-title">Cambiar Contraseña Usuario : {{cambiar_password.name}}</h6>
              <CButtonClose @click="modal_cambiar_password = false" class="text-white"/>
            </template>
            <CForm  validate @submit.prevent="cambiarPassowordSubcuenta($event)">
              <CRow class="ml-1">
                <CCol col="12">
                    <CInput placeholder="Nueva Contraseña" type="password" autocomplete="off" v-model="cambiar_password.password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" required was-validated title="Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 caracteres o más">
                        <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                    </CInput>
                </CCol>
                <CCol col="12">
                    <CInput placeholder="Confirmar Contraseña" type="password" autocomplete="off" v-model="cambiar_password.comfirm_password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" required was-validated title="Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 caracteres o más">
                        <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                    </CInput>
                </CCol>
            </CRow>
            <br>
            <CRow class="ml-1 justify-content-center">
             <CCol col="12" class="text-center">
                <CButton @click="cambiar_clave_modal = false" color="danger">Cancelar</CButton>
                <CButton  color="success" type="submit">Guardar</CButton>
            </CCol>
            </CRow>
            </CForm>
            <!--<CRow>
              <CCol col="12">
                 <h5>Dar Click en Aceptar para Cambiar La Contraseña</h5>
              </CCol>
            </CRow>-->
            
            <template #footer>
              <CButton @click="modal_cambiar_password = false" color="danger" style="display:none;">Cancelar</CButton>
              <CButton v-on:click.stop="cambiarPassowordSubcuenta($event)" color="success" style="display:none;">Aceptar</CButton>
            </template>
          </CModal>
          <CModal
            :show.sync="modal_activar_revendedor"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="lg"
            color="success"
          >
          <template #header>
              <h6 class="modal-title">{{activar_revendedor.titulo}} : {{activar_revendedor.name}}</h6>
              <CButtonClose @click="modal_activar_revendedor = false" class="text-white"/>
            </template>
            <CRow>
              <CCol col="12">
                 <h5>Dar Click en Aceptar para Cambiar El Estado {{activar_revendedor.titulo}}</h5>
              </CCol>
            </CRow>
            
            <template #footer>
              <CButton @click="modal_activar_revendedor = false" color="danger">Cancelar</CButton>
              <CButton v-on:click.stop="habilitarRevendedorSubcuenta($event)" color="success">Aceptar</CButton>
            </template>
          </CModal>
        </div>
   </CContainer>
</template>


<script>
import EventBus from '../../modules/bus';
export default {
  name: 'SubCuenta',
  data () {
    return {
      items: [],
      fields: [
        { key: 'name', label: 'Nombre', _classes: 'font-weight-bold' },
        { key: 'email',label:'Email' },
        { key: 'saldo_actual',label:'Creditos Actual Sms' },
        { key: 'show_details',label:'',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0,
      modal_crear_nueva_sub_cuenta:false,
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Debe Completar Los Datos Vacio',
      form: {
         name:'',
         empresa:'',
         email:'',
         condiciones:false
       },
       submitCrearSubCuenta:false,
       modal_cambiar_password:false,
       modal_activar_revendedor:false,
       activar_revendedor:{
         revendedor:false,
         titulo:'',
         name:'',
         iduser:''
       },
       cambiar_password:{
         iduser:0,
         name:'',
         password:'',
         comfirm_password:''
       }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getSubcuentas(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/subcuenta/usuarios').then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
             this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    crearSubCuenta(e){
        e.preventDefault();
        e = (e) ? e : window.event;
        e.target.disabled = true;
        let $valida = this.validacionForm();
        if ($valida == false ){
          e.target.disabled = false;
          return false;
        } 
        if (this.submitCrearSubCuenta == false && this.form.condiciones == true) {
            this.submitCrearSubCuenta = true;
            this.$store.dispatch('auth/loading_http','visible');
            this.$http.post('/sms/subcuenta/usuarios',{
                email:this.form.email,
                name:this.form.name,
                empresa:this.form.empresa,
              })
              .then(response => {
                if (response.data.status == 'OK') {
                  this.mensaje_success = `Se Creo la SubCuenta Correctamente`;
                  this.mostrarNotificacion ++;
                  this.modal_crear_nueva_sub_cuenta = false;
                  
                  this.form.email = ''
                  this.form.name = ''
                  this.form.empresa = ''
                  this.form.condiciones = false
                  this.getSubcuentas();
                }else{
                  let mensajeError = '';
                    let dataError = response.data;
                    for (const key in dataError) {
                      if (Object.hasOwnProperty.call(dataError, key)) {
                        const element = dataError[key];
                        for (const iterator of element) {
                          mensajeError += `${iterator} \n`
                        }
                      }
                    }
                    this.mensaje_warning = mensajeError
                    this.mostrarNotificacionAlerta += 1;
                }
                this.submitCrearSubCuenta = false;
                e.target.disabled = false;
              }).catch((error)=>{
                this.submitCrearSubCuenta = false;
                e.target.disabled = false;
                this.mostrarNotificacionError ++;
                }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        }else if(this.form.condiciones == false){
            this.mensaje_warning = 'Debe Aceptar Las Condiciones';
            this.mostrarNotificacionAlerta += 1;
            e.target.disabled = false;
        }/*else if(this.submitCrearSubCuenta == true){
            this.mensaje_warning = '';
            this.mostrarNotificacionAlerta += 1;
        }*/
        
    },
    validacionForm(){
       let name = this.form.name;
       let email = this.form.email;
       let empresa = this.form.empresa;
       empresa = empresa.trim(); name = name.trim(); email = email.trim();
       if (name == '' || email == '' || empresa == '') {
            this.mensaje_warning = 'Completar Los Campos Vacio';
            this.mostrarNotificacionAlerta += 1;
         return false;
       }
       return true;
    },
    cambiarStatusSubcuenta(id,nombre,status){
      status = status == 1 ? 0 : 1;
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.put('/sms/subcuenta/usuarios/'+id,{
            status:status,
            tipo_update:'estado'
        })
        .then(response => {
          if (response.data.status) {
            this.mensaje_success = `Usuario ${nombre} ${status == 1 ? 'Activado' : 'Desactivado'} Correctamente`;
            this.mostrarNotificacion ++;
            this.getSubcuentas();
            this.setValueDefaultMessageSuccess();
          }else{
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    abrirModalCambiarPassword(id,name){
      this.cambiar_password.iduser = id;
      this.cambiar_password.name = name;
      this.modal_cambiar_password = true;
    },
    cambiarPassowordSubcuenta(e){
        e = (e) ? e : window.event;
        e.target.disabled = true;
        let id = this.cambiar_password.iduser;
        if (this.cambiar_password.comfirm_password !== this.cambiar_password.password) {
            this.mostrarNotificacionAlerta++
            this.mensaje_warning='Las Contraseñas No Coinciden'
            e.target.disabled = false;
            return false;
        }else if((this.cambiar_password.comfirm_password).trim() == '' && (this.cambiar_password.password).trim() == ''){
            this.mostrarNotificacionAlerta++
            this.mensaje_warning='Debe Completar Los Datos Vacio'
            e.target.disabled = false;
            return false;
        }else if((this.cambiar_password.password).length < 6){
            this.mostrarNotificacionAlerta++
            this.mensaje_warning='La Contraseña debe Tener Minimo 6 Caracteres'
            e.target.disabled = false;
            return false;
        }else{
            this.$store.dispatch('auth/loading_http','visible');
            let id = this.cambiar_password.iduser;
            this.$store.dispatch('auth/loading_http','visible');
            this.$http.post('/sms/cambiar/clave',{
                iduser:id,
                tipo:'subcuenta',
                password:this.cambiar_password.password,
              }).then(response =>{
                let data = response.data;
                if (data == "OK"){
                    this.mensaje_success = `Usuario ${this.cambiar_password.name} Se le Cambio Correctamente La Contraseña`;
                    this.mostrarNotificacion ++;
                }
                this.cambiar_password.comfirm_password = '' 
                this.cambiar_password.password = ''
                this.modal_cambiar_password = false;
              }).catch(error=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
        }
        e.target.disabled = false;
        /*this.$http.put('/sms/subcuenta/usuarios/'+id,{
            tipo_update:'password'
        })
        .then(response => {
          if (response.data.password) {
            this.modal_cambiar_password = false;
            this.mensaje_success = `Usuario ${this.cambiar_password.name} Se le Cambio Correctamente La Contraseña`;
            this.mostrarNotificacion ++;
            this.getSubcuentas();
            this.setValueDefaultMessageSuccess();
            e.target.disabled = false;
          }else{
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              e.target.disabled = false;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));*/
    },
    setValueDefaultMessageSuccess(){
      setTimeout(() => {this.mensaje_success = 'Datos Guardado Correctamente.'},5000);
    },
    abrirModalRevendedor(id,name,revendedor){
      this.activar_revendedor.iduser = id;
      this.activar_revendedor.name = name;
      this.activar_revendedor.revendedor = revendedor;
      this.activar_revendedor.titulo = revendedor == 0 ? 'Activar Revendedor' : 'Desactivar Revendedor';
      this.modal_activar_revendedor = true;
    },
    habilitarRevendedorSubcuenta(e){
        e = (e) ? e : window.event;
        e.target.disabled = true;
        let id = this.activar_revendedor.iduser;
        this.$http.put('/sms/subcuenta/usuarios/'+id,{
            tipo_update:'revendedor'
        })
        .then(response => {
          if (response.data.revendedor) {
            this.modal_activar_revendedor = false;
            this.mensaje_success = `Usuario ${this.activar_revendedor.name} ${this.activar_revendedor.titulo} Se le Cambio Correctamente`;
            this.mostrarNotificacion ++;
            this.getSubcuentas();
            this.setValueDefaultMessageSuccess();
            e.target.disabled = false;
          }else{
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              e.target.disabled = false;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    creditosConversorMoneda(value){
        const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
        })
        return (formatterDolar.format(value)).replace('$','');
    },
  },
  mounted(){
    setTimeout(() => {
      this.getSubcuentas();
    }, 200);
  },
  created: function() {
        EventBus.$on('mostrarNotificacion', (item) => {
          console.log(item);
            //this.comments.push(item)
        });
  },
  computed: {
    validaMarcaBlanca(){
      return this.$store.state.auth.user.sms.marca_blanca;
    }
  }
}
</script>
